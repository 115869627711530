/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


	      if ($('.slider-career')[0]){
		      $('.slider-career').slick({
			      autoplay: true,
			      autoplaySpeed: 7000,
			      dots: false,
			      arrows: true
		      });
	      }

	      if ($('.slider-testimonials')[0]){
		      //// appears on individual portfolio items - only needed in case there is more than one testimonial
		      $('.slider-testimonials').slick({
			      autoplay: true,
			      autoplaySpeed: 7000,
			      dots: false
		      });
	      }

	      if ($('.slider-allthings')[0]){
		      //// allthings-module slider
		      $('.slider-allthings').slick({
			      //autoplay: true,
			      //dots: false,
			      //arrows: false//
			      autoplay: false,
			      dots: false,
			      arrows: true,
			      appendArrows: $('.arrow-append')
			     // infinite: false
		      });

	      }

	      if ($('.slider-full-allthings')[0]){
		      //// allthings module full-width slider
		      $('.slider-full-allthings').slick({
			      autoplay: true,
                  autoplaySpeed: 7000,
			      dots: false
		      });
	      }

	      if ($('.slider-clients')[0]){
		      //// clients-module slider
		      $('.slider-clients').slick({
			      autoplay: true,
			      dots: false,
			      arrows: false,
			      infinite: true,
			      speed: 500,
			      fade: true,
			      cssEase: 'linear'
		      });
	      }

        // stop video playback on modal close
        // http://stackoverflow.com/a/25069916
        $(".video-modal").on('hidden.bs.modal', function (e) {

          var id = $(this).attr('id');
          var target = "#" + id + ".video-modal iframe";

          $(target).attr("src", $(target).attr("src"));
        });

	      /**********************************************
	       * ********************************************
	       * ******* Equal heights
	       * ********************************************
	       */
	      var screenWidth = $( window ).width();
	      ///// Equalize height in rows
	      var equalheight = function (container, minsize) {
		      var currentTallest = 0,
				      currentRowStart = 0,
				      rowDivs = [],
				      $el,
				      $currentDiv,
				      topPosition = 0;
		      $(container).each(function () {
			      screenWidth = $( window ).width();
			      var classes = $(container).attr('class').split(' ');
			      for(var i = 0; i < classes.length; i++) {
				      //console.log(classes[i]);
			      }

			      $el = $(this);
			      $($el).height('auto');

			      if(screenWidth > minsize){
				      topPostion = $el.position().top;
				      if (currentRowStart !== topPostion) {
					      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
						      rowDivs[currentDiv].height(currentTallest);
					      }
					      rowDivs.length = 0; // empty the array
					      currentRowStart = topPostion;
					      currentTallest = $el.height();
					      rowDivs.push($el);
				      } else {
					      rowDivs.push($el);
					      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				      }
				      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					      rowDivs[currentDiv].height(currentTallest);
				      }
			      }
		      });
	      };
	      ////// end equalheight();
        function wrappedResize(parentClasses, elemClass) {
          var capHeight = 0;
          $(elemClass).css({height: 'auto'});

          if(!$('.navbar-toggle').is(':visible')){

            $(parentClasses).each(function(e){
              capHeight = 0;
              $(elemClass,$(this)).each(function(e){
                if($(this).outerHeight() > capHeight) {
                  capHeight = $(this).outerHeight();
                }
              });
              $(elemClass,$(this)).css({height: capHeight});
            });
	          
          }else{
          	//console.log( 'else' );
          }
        }
	      ////////////////////////////////
	      ////////////////////////////////

	      //// general equalize heights call after load fallback
	      // function equalizeHeights(){
		     //  equalheight('.card', 767);
	      // }
	      // setTimeout(function(){ equalizeHeights(); }, 1100); //

        function resizeFuntions() {
	        if ($(".home-section")[0]){
		        wrappedResize('.home-section, .blog, .card-repeater','.card .thumbnail');
	        }
	        if ($(".repeater-tabs")[0]){
		        wrappedResize('.repeater-tabs','.tablinks');
	        }
	        if ($(".expertise-section")[0]){
		        wrappedResize('.expertise-section','.expertise-elem');
	        }
	        if ($(".card-repeater")[0]){
		        wrappedResize('.card-repeater','.thumbnail');
	        }
	        if ($(".news-module")[0]){
		        wrappedResize('.news-module','.thumbnail');
	        }
	        if ($(".leadership-section")[0]){
		        wrappedResize('.leadership-section','.card');
	        }

        }

        $('#menu-main-menu > .dropdown').click(function(e) {
          var openDropdowns = $('#menu-main-menu > .dropdown.open');
          if($(this).hasClass('open')) {
            openDropdowns.removeClass('open');
            openDropdowns.find('>.caret-wrapper').removeClass('flipped');
          }
          else {
            openDropdowns.removeClass('open');
            openDropdowns.find('>.caret-wrapper').removeClass('flipped');
            $(this).addClass('open');
            $(this).find('>.caret-wrapper').addClass('flipped');
          }
        });

        $('#menu-main-menu > .dropdown>a').click(function(e) {
          e.preventDefault();
        });

        $('#menu-main-menu > .dropdown .mega-menu .no-link>a').click(function(e) {
          e.preventDefault();
          e.stopPropagation();
        });

        var videoIframe = $('.embed-container> iframe');
        var videoWidth, videoHeight = 0;

        function videoResizeRatio() {
          var videoRatio = videoWidth/videoHeight;
          var tempWidth = videoIframe.width();
          videoIframe.css({height: tempWidth/videoRatio});
        }

        if(videoIframe.length > 0) {
          videoWidth = videoIframe.parent().parent().width();
          videoHeight = videoIframe.parent().parent().height();
          videoIframe.css({width: '100%'});
          videoResizeRatio();
        }

        $('header.navbar .navbar-nav>li >.dropdown-menu').each(function() {
          var currentMenu = $(this);
          $(this).wrap('<div class="mega-menu dropdown-menu"><div class="container"></div></div>');

          var firstLevel = currentMenu.children();
          if(firstLevel.length > 1) {
            var subMenu = $(firstLevel).find('.dropdown-menu');

            if(subMenu.length > 0) {
              currentMenu.addClass('row');
              firstLevel.addClass('col-sm-6 col-lg-5');

              subMenu.each(function() {
                var currentSubMenu = $(this);
                var secondLevel = $(currentSubMenu).children();
                if(secondLevel.length > 5) {
                  currentSubMenu.addClass('row');
                  secondLevel.addClass('col-md-6');

                  var currentElem = 1;
                  secondLevel.each(function() {
                    if(currentElem % 2 == 0) {
                      $(this).after('<div class="clearfix"></div>');
                    }
                    currentElem++;
                  });
                }
              });
            }
          }
        });

        $('.search-toggle').click(function(e) {
          e.preventDefault();
          $('#menu-main-menu > .dropdown.open').removeClass('open');
          $('.search-toggle, .search-wrapper').toggleClass('active');
        });

        setTimeout(function(){
          resizeFuntions();
        }, 1100);


	      var imageCounter = 0;
	      function resizeTimer() {
		      //console.log('resize ' + imageCounter);//
		      resizeFuntions();
		      imageCounter++;
		      if(imageCounter > 10){
			      clearInterval(catchImages);
		      }
	      }
	      var catchImages = setInterval(resizeTimer, 2000);

        $(window).resize(function() {
          resizeFuntions();
          videoResizeRatio();
        });

	      $( window ).load(function() {
		      //console.log('fully loaded ');
		      resizeFuntions();
	      });

		////// IMPORTANT - CORE HAS BEEN HACKED /////
	    ///// if videos stop playing after a WordPress update see this post https://stackoverflow.com/a/52322369
	      ///// and make the appropriate edit in wp-embed.min.js - following
	      ///// if(d&&!(d.secret||d.message||d.value))
	      ///// end of alarm
		/* the video overlay only gets clicked once and you only need to start a player once, so recreating the reference is quickest way */
        $('.video-overlay').click(function(e) {
          e.preventDefault();

          //$(".embed-container iframe")[0].src += "&autoplay=1"; this was put in by prev dev and wasn't firing
          $(this).addClass('inactive');
	        var iframe = $( this ).prev().find( "iframe" );
	        iframe.css("height","100%");//// for whatever reason the iframe started collapsing
	        //var iframe = document.querySelector('iframe');//// this is vimeo-suggested code

	        var player = new Vimeo.Player(iframe);

	        // player.getVideoTitle().then(function(title) {
		     //    console.log('title:', title); //// good for testing that your references are on point
	        // });

	        player.play();

	        $('.slider-allthings,.slider-full-allthings').on('afterChange', function () {
		        player.pause();
	        });

        });

	      /**********************************************
	       * ********************************************
	       * ******* Basic Tabs
	       * ********************************************
	       */
	      ///// modified to handle multiple tabgroups on a single page
	      function openTab(evt, tabName, tabGroup) {
		      // variables
		      var i, tabcontent, tablinks;

		      tabGroup = '#' + tabGroup;
		      jQuery(tabGroup).find('.tabcontent').removeClass('active');
		      jQuery(tabGroup).find('.tablinks').removeClass('active');

		      // Show the current tab, and add an "active" class to the button that opened the tab
		      var compTab = tabGroup + ' #' + tabName + '-Tab';
		      jQuery(compTab).addClass('active');
		      var compTabContent = tabGroup + ' #' + tabName;
		      jQuery(compTabContent).addClass('active');
	      }

	      $('.tablinks').click(function() {
		      var val = $(this).attr('id');
		      var par = $(this).parent().parent().parent().attr('id'); //// pass the parent to set scope
		      openTab(event, val, par);
	      });

	      ////// end openTab() and calls;
	      //////////////////////////////////
	      //////////////////////////////////


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
	      //// slider that has add-on barnacle stuck to it
	      //// sliders on the homepage portfolio section
	      $('.slider-portfolio').slick({
		      autoplay: true,
		      autoplaySpeed: 7000,
		      //asNavFor: '.slider-portfolio-addon',
		      dots: true,
		      arrows: false
	      });

	      $('.slider-hero').slick({
		      autoplay: true,
		      autoplaySpeed: 7000,
		      arrows: false,
		      asNavFor: '.slider-addon',
		      dots: true
	      });

	      $('.slider-addon').slick({
		      autoplay: true,
		      autoplaySpeed: 7000,
		      dots: false,
		      asNavFor: '.slider-hero',
		      arrows: true
	      });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
	  // portfolio
	  'post_type_archive_portfolio': {
		  init: function() {

			  $('#expertise').change(function(){
				  var thisCat = $(this).val();
				  window.location.href = "/portfolio/?catid=" + thisCat;
			  });

			  $('#sector').change(function(){
				  var thisCat = $(this).val();
				  window.location.href = "/portfolio/?tagid=" + thisCat;
			  });

		  },
		  finalize: function() {
			  $('.slider-hero').slick({
				  autoplay: true,
				  autoplaySpeed: 7000,
				  arrows: false,
				  asNavFor: '.slider-addon',
				  dots: true
			  });

			  $('.slider-addon').slick({
				  autoplay: true,
				  autoplaySpeed: 7000,
				  dots: false,
				  asNavFor: '.slider-hero',
				  arrows: true
			  });
		  }
	  },
	  // single portfolio
	  'single_portfolio': {
		  init: function() {
			////
		  },
		  finalize: function() {
			  $('.slider-hero').slick({
				  autoplay: true,
				  autoplaySpeed: 7000,
				  arrows: false,
				  asNavFor: '.slider-addon',
				  dots: true
			  });

			  $('.slider-addon').slick({
				  autoplay: true,
				  autoplaySpeed: 7000,
				  dots: false,
				  asNavFor: '.slider-hero',
				  arrows: true
			  });
		  }
	  },
	  // single sector
	  'page_template_template_sector': {
		  init: function() {
			  //
		  },
		  finalize: function() {
			  $('.slider-hero').slick({
				  autoplay: true,
				  autoplaySpeed: 7000,
				  arrows: false,
				  asNavFor: '.slider-addon',
				  dots: true
			  });

			  $('.slider-addon').slick({
          autoplay: true,
          autoplaySpeed: 7000,
				  dots: false,
				  asNavFor: '.slider-hero',
				  arrows: true
			  });
		  }
	  }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
